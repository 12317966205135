import React from "react";
import ClientSlider from "./components/ClientSlider";

const ClientsArea = () => {
  return (
    <>
      <section className="brand-area">
        <div className="container">
          
          <div className="row">
            <div className="col-md-12">
              <div className="brand">
                <ClientSlider />
              </div>
            </div>
          </div>
        </div>
      </section>
   
    </>
  );
};

export default ClientsArea;
