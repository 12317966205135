import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../config/BaseUrl";

const initialState = {
  projecttotal: localStorage.getItem("projecttotal")
    ? JSON.parse(localStorage.getItem("projecttotal"))
    : [],
  homeImage: "",
  projectImage: [],
  isHomeImageLoading: true,
  isProjectImageLoading: true,
  isProjectLoading: true,
  imagemodel: false,
};

export const getProject = createAsyncThunk(
  "Project/getProject",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/project/all`;
      const resp = await axios(url);
      return resp.data.projects;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const ProductSlice = createSlice({
  name: "productAll",
  initialState,
  reducer: {
    imageModelStatus(state, action) {
      state.imagemodel = action.payload;
    },
  },
  extraReducers: {
    [getProject.pending]: (state) => {
      state.isProjectLoading = true;
    },
    [getProject.fulfilled]: (state, action) => {
      state.projecttotal = action.payload;
      localStorage.setItem("projecttotal", JSON.stringify(action.payload));
      state.isProjectLoading = false;
    },
    [getProject.rejected]: (state, action) => {
      state.isProjectLoading = true;
    },
  },
});

export const { imageModelStatus } = ProductSlice.actions;
export default ProductSlice.reducer;
