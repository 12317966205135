import React from "react";
import ClientsArea from "../home/ClientsArea";
import { Link } from "react-router-dom";

const WhyUs = () => {
  return (
    <>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>Why Us</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>Why Us</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="design-desc-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tab-manu">
                <ul className="tab-manu-items text-center" role="tablist">
                  <li className="single-item" data-tab-name="resid">
                    <a
                      href="#resid"
                      aria-controls="resid"
                      role="tab"
                      data-toggle="tab"
                      className="clearfix"
                    >
                      CHOICE
                    </a>
                  </li>
                  <li className="single-item active" data-tab-name="commer">
                    <a
                      href="#commer"
                      aria-controls="commer"
                      role="tab"
                      data-toggle="tab"
                      className="clearfix"
                    >
                      PARTNERSHIP
                    </a>
                  </li>
                  <li className="single-item" data-tab-name="corp">
                    <a
                      href="#corp"
                      aria-controls="corp"
                      role="tab"
                      data-toggle="tab"
                      className="clearfix"
                    >
                      INTERIOR
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                {/*Start single tab content*/}
                <div className="tab-pane fade in" id="resid">
                  <div className="inner-content">
                    <div className="row">
                      <div className="col-md-6">
                        {/*Start design single carousel*/}
                        <div
                          id="design-single-carousel"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#design-single-carousel"
                              data-slide-to={0}
                              className="active"
                            />
                            <li
                              data-target="#design-single-carousel"
                              data-slide-to={1}
                            />
                            <li
                              data-target="#design-single-carousel"
                              data-slide-to={2}
                            />
                          </ol>
                          <div className="carousel-inner" role="listbox">
                            <div className="item active">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-1.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-2.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-3.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*End design single carousel*/}
                      </div>
                      <div className="col-md-6">
                        {/*Start text-holder*/}
                        <div className="text-holder">
                          <div className="sec-title-two">
                            <h3>IT’S YOUR CHOICE</h3>
                          </div>
                          <div className="text bold">
                            <p>
                              As no two persons are the same – We understand
                              that your needs are entirely unique. That is why,
                              we at Space Design Studio Interiors offer a choice
                              of packages to reflect your individual tastes,
                              requirements and Budget.
                            </p>
                          </div>
                          <div className="caption-box">
                            <h3>
                              We, the highly experienced team of flat interior
                              designers in hyderabad, are competent to help you
                              turn your vision of a dream home into a living
                              reality.
                            </h3>
                          </div>
                        </div>
                        {/*End text-holder*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/*End single tab content*/}
                {/*Start single tab content*/}
                <div className="tab-pane fade in active" id="commer">
                  <div className="inner-content">
                    <div className="row">
                      <div className="col-md-6">
                        {/*Start design single carousel*/}
                        <div
                          id="design-single-carousel-0"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#design-single-carousel-0"
                              data-slide-to={0}
                              className="active-1"
                            />
                            <li
                              data-target="#design-single-carousel-0"
                              data-slide-to={1}
                            />
                            <li
                              data-target="#design-single-carousel-0"
                              data-slide-to={2}
                            />
                          </ol>
                          <div className="carousel-inner" role="listbox">
                            <div className="item active">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-1.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-2.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-3.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*End design single carousel*/}
                      </div>
                      <div className="col-md-6">
                        {/*Start text-holder*/}
                        <div className="text-holder">
                          <div className="sec-title-two">
                            <h3>OUR PARTNERSHIP WITH YOU</h3>
                          </div>
                          <div className="text bold">
                            <p>
                              As our partner, we invite you to tap into our rich
                              well of expertise and draw from our exclusive list
                              of resources and contacts. In doing so, we can
                              create a home that is entirely individual and
                              reflective of your personality.
                            </p>
                            <p>
                              Enjoy choosing your own colour schemes and range
                              of materials for each room that our team of
                              designers has pre-selected for you, based on a
                              thorough research of the India’s top Suppliers. If
                              your time is scarce, simply allow our designers to
                              do all the work based on your own specifications
                              and taste.
                            </p>
                          </div>
                        </div>
                        {/*End text-holder*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/*End single tab content*/}
                {/*Start single tab content*/}
                <div className="tab-pane fade in" id="corp">
                  <div className="inner-content">
                    <div className="row">
                      <div className="col-md-6">
                        {/*Start design single carousel*/}
                        <div
                          id="design-single-carousel-1"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#design-single-carousel-1"
                              data-slide-to={0}
                              className="active"
                            />
                            <li
                              data-target="#design-single-carousel-1"
                              data-slide-to={1}
                            />
                            <li
                              data-target="#design-single-carousel-1"
                              data-slide-to={2}
                            />
                          </ol>
                          <div className="carousel-inner" role="listbox">
                            <div className="item active">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-1.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-2.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-3.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*End design single carousel*/}
                      </div>
                      <div className="col-md-6">
                        {/*Start text-holder*/}
                        <div className="text-holder">
                          <div className="sec-title-two">
                            <h3>FLAT INTERIOR DESIGNERS IN HYDERABAD</h3>
                          </div>
                          <div className="text bold">
                            <p>
                              At Space Design Studio, we conceptualize interiors
                              with 3D effects that combine creative space
                              planning for both commercial and residential
                              properties, such as high-rise apartments, villas,
                              Hotels, Hospitals and offices. Our turnkey package
                              offers the regions most comprehensive and
                              cost-effective interior design services.
                            </p>
                            <p>
                              The secret to our success is a combination of
                              highly personalized customer care, together with
                              producing an impressive state of innovative
                              designs for both domestic and corporate client.
                            </p>
                          </div>
                          <div className="caption-box">
                            <h3>
                              We are a leading Design house, based in the heart
                              of the Hyderabad
                            </h3>
                          </div>
                        </div>
                        {/*End text-holder*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/*End single tab content*/}
              </div>
            </div>
          </div>
        </div>
      </section>

        {/*Start choose area*/}
        <section
        className="choose-area"
        style={{ backgroundImage: "url(images/about/choose-bg.jpg)" }}
      >
        <div className="container">
          <div className="sec-title text-center">
            <h2>Why choose Interior</h2>
            <span className="border" />
          </div>
          <div className="row">
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-internet" />
                </div>
                <div className="text-holder">
                  <h3>We have highly professional team</h3>
                  <p className="bold1">
                    We have a team of creative and hard working professionals
                    with advance and innovative ideas.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-idea" />
                </div>
                <div className="text-holder">
                  <h3>We are commited</h3>
                  <p className="bold1">
                    We are commited to complete our work within the given time
                    period.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-construction" />
                </div>
                <div className="text-holder">
                  <h3>We deliver best results</h3>
                  <p className="bold1">
                    We always give our best work so that our clients can get as
                    what they want.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
          </div>
        </div>
        
      </section>
      {/*End choose area*/}

      {/*Start What we do*/}
      <section
        className="choose-area"
        style={{ backgroundImage: "url(images/about/choose-bg.jpg)" }}
      >
        <div className="container">
          <div className="sec-title text-center">
            <h2>OUR SERVICES</h2>
            <span className="border" />
          </div>
          <div className="row">
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-internet" />
                </div>
                <div className="text-holder">
                  <h3>Hotel & Resturant Interior Design</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-idea" />
                </div>
                <div className="text-holder">
                  <h3>Residential Interior Design</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-construction" />
                </div>
                <div className="text-holder">
                  <h3>Exterior design</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}

            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-internet" />
                </div>
                <div className="text-holder">
                  <h3>Flats</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-idea" />
                </div>
                <div className="text-holder">
                  <h3>Villas</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
          </div>
        </div>
      </section>
      {/*End What we do*/}

      <ClientsArea />
    </>
  );
};

export default WhyUs;
