import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper";

const AwardsSlider = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={5}
        draggable={true}
        modules={[Autoplay]}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        className="ClientSwiper" 
        // breakpoints={{
        //     360: {
        //         slidesPreView: 2,
        //         spaceBetween: 5,
        //     },
        //     480: {
        //         slidesPreView: 3,
        //         spaceBetween: 5,
        //     },
        //     720: {
        //         slidesPreView: 4,
        //         spaceBetween: 5,
        //     },
        //     1080: {
        //         slidesPreView: 5,
        //         spaceBetween: 5,
        //     },
        // }}
      >
        <SwiperSlide>
          <div className="col-md-12">
            <div className="testimonials-carousel" >
              <div className="single-slide-item">
                <div className="img-box">
                  <img  src="images/award/a1.jpg" alt="Awesome Image"  />
                </div>
                <div className="text-box">
                  <span className="flaticon-right" />
                  <div className="text">
                    <p className="bold">
                      INDIA’S MOST PROMINENT ARCHITECT & DESIGN AWARDS – 2019{" "}
                      <br />
                      MERIT AWARDS AND MARKET RESEARCH PVT. LTD..
                    </p>
                    <div className="">
                      <div className="col-lg-12  ">
                        <h3 className="mb-lg-2">PRESENTED TO :</h3>

                        <h3>(SPACE DESIGN STUDIO )</h3>
                        <h4>AR. ABDUL JAVID </h4>
                        <br />
                        <br />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <h3 className="mb-lg-2">PRESENTED By :</h3>
                      <div className="col-lg-6 ">
                        <h3>I.D SUNITA KOHLI</h3>
                        <h4> (INDIAN INTERIOR DESIGNER)</h4>
                      </div>
                      <div className="col-lg-6">
                        <h3>AR. DIKSHU C KUKREJA </h3>
                        <h4> (INDIAN ARCHITECT)</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="col-md-12">
            <div className="testimonials-carousel">
              <div className="single-slide-item">
                <div className="img-box">
                  <img src="images/award/a2.jpg" alt="Awesome Image" />
                </div>

                <div className="text-box">
                  <span className="flaticon-right" />
                  <div className="text">
                    <p className="bold">
                      THE GLOBAL CHOICE AWARDS – 2021 <br />
                      Most Promising Interior Designing Company In Hyderabad.
                    </p>
                    <div className="">
                      <div className="col-lg-12  ">
                        <h3 className="mb-lg-2">PRESENTED TO :</h3>

                        <h3>(SPACE DESIGN STUDIO )</h3>
                        <h4>AR. ABDUL JAVID JAYAAS </h4>
                        <br />
                        <br />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <h3 className="mb-lg-2">PRESENTED By :</h3>
                      <div className="col-lg-6 ">
                        <h3>LARA DUTTA </h3>
                        <h4 className="">MISS UNIVERSE / ENTREPRENEUR</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="col-md-12">
            <div className="testimonials-carousel">
              <div className="single-slide-item">
                <div className="img-box">
                  <img src="images/award/a3.jpg" alt="Awesome Image" />
                </div>

                <div className="text-box">
                  <span className="flaticon-right" />
                  <div className="text">
                    <p className="bold">
                      INDIA’S MOST PROMINENT INDIAN ICON AWARDS - 2021
                      <br />
                      MERIT AWARDS AND MARKET RESEARCH PVT. LTD. <br />
                      FINEST INTERIOR DESIGNER IN HYDERABAD
                    </p>
                    <div className="">
                      <div className="col-lg-12  ">
                        <h3 className="mb-lg-2">PRESENTED TO :</h3>

                        <h3>(SPACE DESIGN STUDIO )</h3>
                        <h4>AR. ABDUL JAVID </h4>
                        <br />
                        <br />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <h3 className="mb-lg-2">PRESENTED By :</h3>
                      <div className="col-lg-6 ">
                        <h3>MR. KAPIL DEV </h3>
                        <h4> (FORMER INDIAN CRICKETER)</h4>
                      </div>
                      <div className="col-lg-6">
                        <h3>MS. SUSHMITA SEN </h3>
                        <h4> (MISS UNIVERSE )</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="col-md-12">
            <div className="testimonials-carousel">
              <div className="single-slide-item">
                <div className="img-box">
                  <img src="images/award/a4.jpg" alt="Awesome Image" />
                </div>
                <div className="text-box">
                  <span className="flaticon-right" />
                  <div className="text">
                    <p className="bold">
                      ARCHITECTURE & INTERIOR DESIGN (EXCELLENCE AWARDS &
                      CONFERENCE - 2023)
                      <br />
                      TRUSTED & OUTSTANDING ARCHITECTURAL DESIGN AND INTERIOR
                      DESIGN FIRM OF THE YEAR 2023
                    </p>
                    <div className="">
                      <div className="col-lg-12  ">
                        <h3 className="mb-lg-2">PRESENTED TO :</h3>

                        <h3>(SPACE DESIGN STUDIO )</h3>
                        <h4>AR. ABDUL JAVID </h4>
                        <br />
                        <br />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <h3 className="mb-lg-2">PRESENTED By :</h3>
                      <div className="col-lg-6 ">
                        <h4>
                          {" "}
                          BEGINUP RESEARCH INTELLIGENCE PRIVATE LIMITED
                          BENGALURE
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default AwardsSlider;
