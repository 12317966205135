import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Header = () => {
  const { categorytotal } = useSelector((store) => store.categoryAll);


  const [showAbout, setShowAbout] = useState(true);
  const [showInterior, setShowInterior] = useState(true);
  const [showGallery, setShowGallery] = useState(true);

  const closemenu = () => {
    document.getElementById("mytogglebtn").classList.add("collapsed");
    document.getElementById("menu").classList.remove("in");
  };

  return (
    <>
      {/*============= Top bar start================ */}
      <section className="top-bar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5">
              <div className="contact-info-left clearfix">
                <ul>
                  <li className="phnumber">
                    <span className="flaticon-technology" />
                    <b>(+91) 800 800 9832</b>
                  </li>
                  <li className="text-light">
                    <span className="flaticon-new-email-outline envelop" />
                    <Link
                      to="mailto:javidarchitects@gmail.com"
                      title=""
                      style={{ color: "#fff" }}
                    >
                      <b>javidarchitects@gmail.com</b>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7 col-md-7">
              <div className="contact-info-right">
                <div className="top-social-links">
                  <ul>
                    <li>
                      <a
                        className="ig"
                        href="https://www.instagram.com/spacedeaignstudio99/?igshid=MjljNjAzYmU%3D"
                        target="_blank"
                        title="Instagram"
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="fb"
                        href="https://www.facebook.com/pg/SpaceDesignStudioHyderabad/posts/?ref=page_internal"
                        target="_blank"
                        title="Follow Us on Facebook"
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>

                    <li>
                      <a
                        className="yt"
                        href="https://www.youtube.com/@spacedesignstudio9726/featured"
                        target="_blank"
                        title="Watch Our Videos"
                      >
                        <i className="fa fa-youtube-play" />
                      </a>
                    </li>

                    <li>
                      <a
                        className="ig"
                        href="https://www.instagram.com/spacedesignjavid/?igshid=MjljNjAzYmU%3D"
                        target="_blank"
                        title="Instagram"
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*============= Top bar end================ */}
      {/*============= header bar start================ */}
      <header className="header-area stricky">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="outer-box clearfix">
                <div className="logo">
                  <Link to="/">
                    <img src="/images/logo/company.png" alt="Awesome Logo" />
                  </Link>
                </div>
                <nav className="main-menu">
                  <div className="navbar-header">
                    <button
                      id="mytogglebtn"
                      v
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#menu"
                    >
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                  </div>
                  <div className="navbar-collapse collapse clearfix" id="menu">
                    <ul className="navigation clearfix">
                      <li className="current">
                        <Link to="/" onClick={closemenu}>
                          Home
                        </Link>
                      </li>
                      <li className="dropdown">
                        <Link onClick={closemenu}>ABOUT US</Link>
                        <ul
                          style={
                            showAbout
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {/* <li>
                            <Link to="/about" onClick={closemenu}>
                              About Us
                            </Link>
                          </li> */}
                          <li>
                            <Link to="/mission" onClick={closemenu}>
                              Mission & Vision
                            </Link>
                          </li>
                          <li>
                            <Link to="/whyus" onClick={closemenu}>
                              Why Us
                            </Link>
                          </li>
                        </ul>

                        <div
                          className="dropdown-btn"
                          onClick={() => setShowAbout(!showAbout)}
                        ></div>
                      </li>

                      <li className="">
                        <Link to="/service" onClick={closemenu}>
                          Services
                        </Link>
                      </li>

                      <li className="">
                        <Link to="/awards" onClick={closemenu}>
                          Awards
                        </Link>
                      </li>

                      <li className="dropdown">
                        <Link to="/approach" onClick={closemenu}>
                          INTERIOR
                        </Link>
                        <ul
                          style={
                            showInterior
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li className="">
                            <Link to="/approach" onClick={closemenu}>
                              Our Approach
                            </Link>
                          </li>
                          <li className="">
                            <Link to="/concept" onClick={closemenu}>
                              3D DESIGN CONCEPT
                            </Link>
                          </li>
                          <li className="">
                            <Link to="/projectexect" onClick={closemenu}>
                              Project Execution
                            </Link>
                          </li>
                        </ul>
                        <div
                          className="dropdown-btn"
                          onClick={() => setShowInterior(!showInterior)}
                        ></div>
                      </li>
                      <li className="dropdown">
                        <Link to="/gallery/residential" onClick={closemenu}>
                          GALLERY
                        </Link>
                        <ul style={
                          showGallery
                            ? { display: "block" }
                            : { display: "none" }
                        }>
                          {categorytotal.map((cat, index) => (
                            <li key={index}>
                              <Link to={`/gallery/${cat.slugUrl}`} onClick={closemenu}>
                                {cat.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                        <div
                          className="dropdown-btn"
                          onClick={() => setShowGallery(!showGallery)}
                        ></div>
                      </li>

                      {/* <li className="">
                        <Link to="/blog" onClick={closemenu}>
                          BLOG
                        </Link>
                      </li> */}

                      <li className="">
                        <Link to="/contact" onClick={closemenu}>
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/*============= header bar end================ */}
    </>
  );
};

export default Header;
