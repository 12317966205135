import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ClientsArea from "./home/ClientsArea";
import { useSelector } from "react-redux";

const Gallery = () => {
  const { categorytotal, isCategoryLoading } = useSelector(
    (store) => store.categoryAll
  );
  const { projecttotal, isProjectLoading } = useSelector(
    (store) => store.productAll
  );
  const params = useParams();

  const [project, setProject] = useState([]);
  const [categorydetail, setCategoryDetails] = useState("");

  useEffect(() => {
    let caturl = params.caturl;
    const category = categorytotal.find((data) => data.slugUrl === caturl);
    setCategoryDetails(category);
    console.log(categorydetail, "categorydetail");

    if (category) {
      const categoryId = category._id;
      let products = projecttotal.filter(
        (data) => data.categoryId === categoryId
      );
      setProject(products);
    }
  }, [params.caturl]);

  return (
    <>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>{categorydetail.name}</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row ">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>{categorydetail.name}</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="design-desc-area">
        <div className="container">
          <div className="tab-pane fade in active" id="commer">
            <div className="inner-content">
              <div className="row bold">
                <div className="col-md-6">
                  <div
                    id="design-single-carousel-0"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner" role="listbox">
                      <div className="item active">
                        <div className="single-item">
                          <div className="img-holder">
                            <img
                              src={categorydetail.categoryImage}
                              alt="Awesome Image"
                              width={725}
                              height={460}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="text-holder">
                    <div className="sec-title-two">
                      <h3>{categorydetail.title}</h3>
                    </div>
                    <div className="text">
                      <p>{categorydetail.longDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="project-area" className="pro-withtext-area">
        <div className="container">
          <div className="row project-content masonary-layout filter-layout">
            {project.map((singleProj, index) => (
              <div
                className="single-project-item col-md-4 col-sm-4 col-xs-12 filter-item resid commer corp"
                key={index}
              >
                <div className="img-holder">
                  <img src={singleProj.homeImage} alt="Awesome Image" />
                  <div className="overlay">
                    <div className="box">
                      <div className="content">
                        <div className="icon-holder">
                          <Link
                            to={`/gallery/project/${singleProj.slugUrl}`}
                            data-rel="prettyPhoto"
                            title="Space Design Project"
                          >
                            <span className="flaticon-cross" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-holder">
                  <a>
                    <h3>{singleProj.name}</h3>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
