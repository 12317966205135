import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <!--Start footer area-->   */}
      <footer className="footer-area">
        <div className="container">
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="single-footer-widget pd-bottom">
              <div className="footer-logo">
                <Link to="/">
                  <img
                    src="images/logo/company.png"
                    alt="Awesome Footer Logo"
                  />
                </Link>
              </div>
              <div className="interrio-info bold">
                <p style={{ color: "#9a9a9a" }}>
                  <span>Space Design Studio </span>is a one stop destination in
                  this field by catering to all the requirements &amp;
                  necessities of design of your Dream Home and Commercial Space.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="single-footer-widget pd-bottom">
              <div className="title">
                <h3>Quick Links</h3>
              </div>
              <ul className="popular-news clearfix bold">
                <li className="single-popular-news-item clearfix">
                  <div className="text-holder">
                    <Link to="/">
                      <p>Home</p>
                    </Link>
                  </div>
                </li>
                <li className="single-popular-news-item clearfix">
                  <div className="text-holder">
                    <Link to="/about">
                      <p>About Us</p>
                    </Link>
                  </div>
                </li>
                <li className="single-popular-news-item clearfix">
                  <div className="text-holder">
                    <Link to="/mission">
                      <p>Mission &amp; Vision</p>
                    </Link>
                  </div>
                </li>
                <li className="single-popular-news-item clearfix">
                  <div className="text-holder">
                    <Link to="/whyus">
                      <p>Why Us</p>
                    </Link>
                  </div>
                </li>
                <li className="single-popular-news-item clearfix">
                  <div className="text-holder">
                    <Link to="">
                      <p>Gallery</p>
                    </Link>
                  </div>
                </li>
                <li className="single-popular-news-item clearfix">
                  <div className="text-holder">
                    <Link to="/awards">
                      <p>Awards</p>
                    </Link>
                  </div>
                </li>
                <li className="single-popular-news-item clearfix">
                  <div className="text-holder">
                    <Link to="/blog">
                      <p>Blog</p>
                    </Link>
                  </div>
                </li>
                <li className="single-popular-news-item clearfix">
                  <div className="text-holder">
                    <Link to="/contact">
                      <p>Contact</p>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="single-footer-widget pd-bottom">
              <div className="title">
                <h3>Services</h3>
              </div>
              <ul className="popular-news clearfix bold">
                <li className="single-popular-news-item clearfix">
                  <div className="text-holder">
                    <Link to="/approach">
                      <p>Our Approach</p>
                    </Link>
                  </div>
                </li>
                <li className="single-popular-news-item clearfix">
                  <div className="text-holder">
                    <Link to="/concept">
                      <p>3D Design Concepts</p>
                    </Link>
                  </div>
                </li>
                <li className="single-popular-news-item clearfix">
                  <div className="text-holder">
                    <Link to="/projectexect">
                      <p>Project Execution</p>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div className="single-footer-widget pd-bottom">
              <div className="title">
                <h3>Get in touch</h3>
              </div>
              <ul className="footer-contact-info">
                <li>
                  <div className="icon-holder">
                    <span className="flaticon-building" />
                  </div>
                  <div className="text-holder bold">
                    <p>
                      Pillar Number 143, # 49 & 50,
                      <br /> 4th Floor, United Arcade,
                      <br /> Attapur, Hyderabad,
                      <br /> Telangana-500048
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icon-holder">
                    <span className="flaticon-technology" />
                  </div>
                  <div className="text-holder bold">
                    <p>(+91) 800 800 9832</p>
                  </div>
                </li>
                <li>
                  <div className="icon-holder">
                    <span className="flaticon-e-mail-envelope" />
                  </div>
                  <div className="text-holder bold">
                    <a href="mailto:javidarchitects@gmail.com">
                      <p>javidarchitects@gmail.com</p>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
       
      </footer>

      <section className="footer-bottom-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="copyright-text">
                <p>
                  Designed &amp; Developed By:{" "}
                  <a
                    href="http://dexterous.in/"
                    title="Dexterous target=_blank"
                  >
                    <strong>dexterous.in</strong>
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="footer-menu">
                <ul>

                <li>
                    <a
                      className="ig"
                      href="https://www.instagram.com/spacedeaignstudio99/?igshid=MjljNjAzYmU%3D"
                      target="_blank"
                      title="Instagram"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="fb"
                      href="https://www.facebook.com/pg/SpaceDesignStudioHyderabad/posts/?ref=page_internal"
                      target="_blank"
                      title="Follow Us on Facebook"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="tw"
                      title="Follow Us on Twitter"
                    >
                      <i className="fa fa-twitter" />
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="yt"
                      href="https://www.youtube.com/@spacedesignstudio9726/featured"
                      target="_blank"
                      title="Watch Our Videos"
                    >
                      <i className="fa fa-youtube-play" />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="pt"
                      title="Pinterest"
                    >
                      <i className="fa fa-pinterest" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="li"
                      title="Follow Us on LinkedIn"
                    >
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="fl"
                      title="Watch Our gallery"
                    >
                      <i className="fa fa-flickr" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="gplus"
                      title="Google Plus"
                    >
                      <i className="fa fa-google-plus" />
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="ig"
                      href="https://www.instagram.com/spacedesignjavid/?igshid=MjljNjAzYmU%3D"
                      target="_blank"
                      title="Instagram"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>

                  {/* <li>
                    <a
                      className=""
                      title="Follow Us on hauzz"
                    >
                      <img src="images/logo/download.png" alt="NO" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="ig"
                    
                      title="Bolgger"
                    >
                      <i className="fa fa-rss" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="vk"
                      
                      title="Follow Us on sulekha"
                    >
                      <img src="images/logo/sulekha1.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="vk"
                      title="Follow Us on homify"
                    >
                      <img src="images/logo/homify.jpg" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="vk"
                      title="Follow Us on zingyhomes"
                    >
                      <img src="images/logo/zingyhomes.jpg" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="vk"
                      title="Follow Us on urbanhomez"
                    >
                      <img src="images/logo/Urban.jpg" />
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--End footer bottom area--> */}
      <ul className=" bg-transparent" style={{ position: "sticky",zIndex:999,bottom:20 }}>
          <li>
            <div className="wp-icon">
              <a href=" https://wa.me/6901230123" target="_blank">
                <img
                  style={{marginLeft:10 ,width: 45, height: 45 }}
                  src="/images/whatsapp-icon.png"
                  alt=""
                />
              </a>
            </div>
          </li>
        </ul>
      {/* <!--Scroll to top--> */}
      {/* <div className="scroll-to-top scroll-to-target" data-target="html">
        <span className="fa fa-angle-up" />
      </div> */}
    </>
  );
};

export default Footer;
