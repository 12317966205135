import React from "react";
import ClientsArea from "../home/ClientsArea";
import { Link } from "react-router-dom";

const MissionVision = () => {
  return (
    <>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>mission-vision</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>mission-vision</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link >
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-interrio-area">
        <div className="container">
          <div className="sec-title">
            <h2>
              About <span>Interrio</span>
            </h2>
            <span className="decor" />
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="text-holder bold">
                <h3>
                ABOUT SPACE DESIGN STUDIO
                </h3>
                <p>
                  Space Design Studio Interior is renowned in the field of Best
                  Interior Designer In Hyderabad and house decoration in
                  Hyderabad. Our interior designers cater to the needs of all
                  the requirements & necessities of Interior designing of your
                  Dream Home and Commercial Space.
                </p>
              </div>
            </div>
            {/*Start single item*/}
            <div className="col-md-4">
              <div className="single-item">
                <div className="img-holder">
                  <img src="images/mission.jpg" alt="Awesome Image" />
                  <div className="overlay">
                    <div className="box">
                      <div className="content">
                        <a>
                          <i className="fa fa-link" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-box bold">
                  <h3>Mission</h3>
                  <p>
                    At Space Design Studio we strive to provide exquisite interior design
                    services in addition to supplying high end furnishing
                    solutions. We value the importance of our relationship with
                    clients and work in partnership thus taking pride in our
                    ever-expanding achievements.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4">
              <div className="single-item pdtop">
                <div className="img-holder">
                  <img src="images/visson.jpg" alt="Awesome Image" />
                  <div className="overlay">
                    <div className="box">
                      <div className="content">
                        <a>
                          <i className="fa fa-link" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-box bold">
                  <h3>Vision</h3>
                  <p>
                    At Space Design Studio we look forward in gaining a leading position in
                    the market by providing exquisite interior design services
                    and high end furniture.
                  </p>
                  <hr />
                  {/* <p>Note: Where ever it shows Schemes Convert it into SDS</p> */}
                </div>
              </div>
            </div>
            {/*End single item*/}
          </div>
        </div>
      </section>

      {/*Start choose area*/}
      <section
        className="choose-area"
        style={{ backgroundImage: "url(images/about/choose-bg.jpg)" }}
      >
        <div className="container">
          <div className="sec-title text-center">
            <h2>Why choose Interior</h2>
            <span className="border" />
          </div>
          <div className="row">
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-internet" />
                </div>
                <div className="text-holder ">
                  <h3>We have highly professional team</h3>
                  <p className="bold1">
                    We have a team of creative and hard working professionals
                    with advance and innovative ideas.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-idea" />
                </div>
                <div className="text-holder">
                  <h3>We are commited</h3>
                  <p className="bold1">
                    We are commited to complete our work within the given time
                    period.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-construction" />
                </div>
                <div className="text-holder">
                  <h3>We deliver best results</h3>
                  <p className="bold1">
                    We always give our best work so that our clients can get as
                    what they want.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
          </div>
        </div>
      </section>
      {/*End choose area*/}

      {/*Start What we do*/}
      <section
        className="choose-area"
        style={{ backgroundImage: "url(images/about/choose-bg.jpg)" }}
      >
        <div className="container">
          <div className="sec-title text-center">
            <h2>OUR SERVICES</h2>
            <span className="border" />
          </div>
          <div className="row">
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-internet" />
                </div>
                <div className="text-holder">
                  <h3>Hotel & Resturant Interior Design</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-idea" />
                </div>
                <div className="text-holder">
                  <h3>Residential Interior Design</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-construction" />
                </div>
                <div className="text-holder">
                  <h3>Exterior design</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}

            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-internet" />
                </div>
                <div className="text-holder">
                  <h3>Flats</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-idea" />
                </div>
                <div className="text-holder">
                  <h3>Villas</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
          </div>
        </div>
      </section>
      {/*End What we do*/}

      <ClientsArea />
    </>
  );
};

export default MissionVision;
