import React from "react";
import { Link } from "react-router-dom";
import AwardsSlider from "./home/components/AwardsSlider";

const Awards = () => {
  return (
    <>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>Awards</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>Awards</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="welcome-area">
        <div className="container clearfix">
          <div className="sec-title">
            <h2>
              Awards to <span>Space Design Studio</span>
            </h2>
            <p className="bold">– Architect’s and Interior’s </p>
            <span className="decor" />
            <p className="bold">
              Appreciation Of Award’s Winning for Best Architect and Interior
              Designer In HYDERABAD, T.S.
            </p>
          </div>
        </div>
      </section>

      <section className="testimonial-area">
        <div className="container">
          <div className="sec-title text-center">
            <h2>AWARDS</h2>
            <span className="border" />
          </div>

          <div className="row">
            <AwardsSlider />
          </div>
        </div>
      </section>

      <section className="award" style={{background:"#cecbc6"}}>
          <div className="" style={{paddingTop:10}}>
            <img src="images/award/award.png" alt="Awesome Image" />
          </div>
      </section>
    </>
  );
};

export default Awards;
