import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LastestWork = () => {
  const { categorytotal } = useSelector((store) => store.categoryAll);
  // console.log(categorytotal);
  return (
    <>
      <section
        className="service-area"
        style={{ backgroundImage: "url(images/services/bg.jpg)" }}
      >
        <div className="container">
          <div className="sec-title">
            <h2>Our Latest Work</h2>
            <span className="decor" />
          </div>
          
          <div className="row">
          {categorytotal.map((cat,index)=>(
              <div className="col-md-4 col-sm-4 col-xs-12" key={index}>
              <div className="single-service-item">
                <div className="img-holder">
                  <img src={cat.categoryImage} alt="Awesome Image" />
                  <div className="overlay">
                    <div className="box">
                      <div className="content">
                        <Link to={`/gallery/${cat.slugUrl}`}>
                          <i className="fa fa-link" aria-hidden="true" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-holder">
                  <Link to={`/gallery/${cat.slugUrl}`}>
                    <h3>
                      {cat.name}
                    </h3>
                  </Link>
                  <p>{cat.shortDescription}</p>
                </div>
              </div>
            </div>
            ))}
           
          </div>
        </div>
      </section>
    </>
  );
};

export default LastestWork;
