import React from "react";
import { Link } from "react-router-dom";
import ClientsArea from "../home/ClientsArea";
import LastestProject from "../home/LastestProject";
import { useSelector } from "react-redux";

const ProjectExect = () => {
  const { categorytotal } = useSelector((store) => store.categoryAll);

  return (
    <>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>PROJECT EXECUTION</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>PROJECT EXECUTION</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="welcome-area">
        <div className="container clearfix">
          <div className="sec-title">
            <h2>
              <span>PROJECT </span>EXECUTION
            </h2>
            <span className="decor" />
            <section className="working-area" style={{paddingTop: 40}}>
              <div className="container">
                <div className="sec-title bold" style={{padding: 0}}>
                  <h2 className="text-center">Turnkey Fit Out</h2>
                  <p>
                    Space Design Studio offers a fully comprehensive service for
                    both corporate and domestic clients from start to finish.
                    Our complete turnkey solution and build service ensure
                    speed, efficiency and a seamless co-ordination between each
                    element of the process. Our Services range from design
                    advice and conceptualization to full architectural design
                    and site construction facilities, including planning
                    permission submissions and building control recommendations
                    for an array of sections such as municipality requirements
                    and Civil Defense.
                  </p>
                  <p>
                    The Turnkey package offers a comprehensive and
                    cost-effective interior service that is superior to the
                    current industry standards. Put simply, we transform
                    creative and innovative ideas into a practical reality by
                    making use of a full range of style and approaches that is
                    appropriate to the project and meets your budget demands. We
                    also manage the project to ensure no detail is overlooked
                    and that your home or commercial space is looking exactly as
                    we had envisioned it in the concept stage.
                  </p>
                  <p style={{marginBottom:40}}>
                    Due to our extensive knowledge coupled with several
                    partnerships with some of the India’s top suppliers is
                    guaranteed to give you a result where attention to detail is
                    paramount.
                  </p>
                </div>
                <div className="row bold">
                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="single-working-item">
                      <div className="text-box text-center ">
                        <h3>Site Supervision</h3>
                        <p className="forleft">
                          It is essential that the quality of the building match
                          the bespoke service that we provide.This means that
                          our site supervisor closely monitors the construction
                          work on a frequent basis. We work with only the best
                          contractors and suppliers to ensure construction is on
                          schedule and of superior standard.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="single-working-item">
                      <div className="text-box text-center">
                        <h3>Quality of Finish</h3>
                        <p className="forleft">
                          Our extensive knowledge of interiors coupled with
                          several partnerships with some of the Delhi’s and UP's
                          top suppliers is guaranteed to give you a result where
                          attention to detail is paramount. Similarly our
                          Professional relationships with all third party
                          contractors, consultants and government officials mean
                          no stone is left unturned.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="single-working-item">
                      <div className="text-box text-center">
                        <h3>Control of Cost</h3>
                        <p className="forleft">
                          Our policy for openness and transparency means that we
                          offer a fully itemized breakdown of the cost of the
                          entire project. A quotation is agreed and signed
                          before the start of the project. As we are not fixed
                          to any specific contractor, we are free to source only
                          the best companies who meet our exacting standards and
                          at a realistic Price. You can have peace of mind by
                          knowing that we will remain below budget without
                          compromising on standards or the deadline for
                          delivery.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="single-working-item">
                      <div className="text-box text-center">
                        <h3>Reliability & Timelines</h3>
                        <p className="forleft">
                          As time is money, at the very start we will set a
                          timetable with realistic deadlines and goals for
                          specific projects in place. We will Keep you informed
                          of every step along the way and will ensure that all
                          third parties work according to our schedule.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row bold" >
                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="single-working-item">
                      <div className="text-box">
                        <h3>Innovation & Flexibility</h3>
                        <p className="forleft">
                          Our approach is characterized by our commitment to
                          you, motivation, inspiration and the ability to think
                          outside the box. No project is too big or small for
                          us.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectExect;
