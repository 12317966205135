import React from "react";
import { Link } from "react-router-dom";

const Service = () => {
  return (
    <>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>Services</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>Services</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-big main-color ">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <ul className="i-list medium">
                <li className="i-list-item">
                  <div className="icon">
                    <i className="fa fa-desktop" />{" "}
                  </div>
                  <div className="icon-content">
                    <h3 className="title">HOUSING PROJECTS:</h3>
                    <p className="sub-title">
                      <ul className="bold">
                        <li><>Homes</></li>
                        <li><>Flats</></li>
                        <li><>Row Houses </></li>
                        <li><>Bungalows</></li>
                        <li><>Duplexes</></li>
                        <li><>Plots</></li>
                        <li><>High Rise Multi story residential building</></li>
                      </ul>
                    </p>
                  </div>
                  <div className="iconlist-timeline" />
                </li>
                <li className="i-list-item">
                  <div className="icon">
                    <i className="fa fa-code" />
                  </div>
                  <div className="icon-content">
                    <h3 className="title">Domains catered to :</h3>

                    <p className="sub-title">
                      <ul className="bold">
                        <li>
                          How to select a right Plot/Flats/Row
                          Houses/Bungalows/Duplexes
                        </li>
                        <li>Entrance Gate to home</li>
                        <li>Staircases</li>
                        <li>Courtyard/Aangan</li>
                        <li>Drawing Room</li>
                        <li>Meeting halls</li>
                        <li>Kitchen</li>
                        <li>Bedrooms</li>
                        <li>Dressing rooms</li>
                        <li>Guest Bed Room</li>
                        <li>Servants Room</li>
                        <li>Balconies</li>
                        <li>Dining Hall</li>
                        <li>Pooja Room</li>
                        <li>Children Study Room</li>
                        <li>Store Rooms</li>
                        <li>Washrooms/Toilets/Bathrooms</li>
                        <li>Water Tanks (Under Ground and Overhead Tanks)</li>
                        <li>Doors/Window shapes</li>
                        <li>Positions of Beds and Almirahs and Study Tables</li>
                        <li>
                          Placements of Plants/Mirrors/Dustbins/Shoes/Color of
                          curtains
                        </li>
                        <li>Gym in House</li>
                        <li>Basement Vastu</li>
                        <li>Parking Lots</li>
                      </ul>
                    </p>
                  </div>
                  <div className="iconlist-timeline" />
                </li>
                <li className="i-list-item">
                  <div className="icon">
                    <i className="fa fa-heart-o" />
                  </div>
                  <div className="icon-content">
                    <h3 className="title">OTHERS :</h3>
                    <p className="sub-title">
                      <ul className="bold">
                        <li>RESORTS AND CLUBS</li>
                        <li>GARDENS</li>
                        <li>EDUCATIONAL INSTITUTES</li>
                        <li>MINES</li>
                        <li>TEMPLES</li>
                        <li>OTHERS</li>
                      </ul>
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-4">
              <ul className="i-list medium">
                <li className="i-list-item">
                  <div className="icon">
                    {" "}
                    <i className="fa fa-diamond" />{" "}
                  </div>
                  <div className="icon-content">
                    <h3 className="title">BUSINESS PREMISES :</h3>
                  </div>

                  <div className="icon-content">
                    <h4 className="title">OFFICES AND COMMERCIAL PREMISES :</h4>
                    <p>
                      <ul className="bold">
                        <li>
                          Selection of Office space and Commercial Premises.
                        </li>
                        <li>Entrance Gate</li>
                        <li>Roads alignments and positions</li>
                        <li>Generators and power Plants</li>
                        <li>Staircase</li>
                        <li>Director's Room</li>
                        <li>Meeting Room/Conference Rooms</li>
                        <li>Training Halls</li>
                        <li>Employees seating areas</li>
                        <li>Cashier Place</li>
                        <li>Quality Control Dept</li>
                        <li>Office Kitchen/Pantry/Canteen</li>
                        <li>Pooja Area</li>
                        <li>Store</li>
                        <li>Quarters Area for employees</li>
                        <li>Gym and Recreation area</li>
                        <li>Washrooms/Toilets/Bathrooms</li>
                        <li>Water Tanks (Under Ground and Overhead Tanks)</li>
                        <li>Doors/Windows</li>
                        <li>
                          Placements of Plants/Mirrors/Color/decoration pcs/
                          curtains
                        </li>
                      </ul>
                    </p>
                  </div>
                  <div className="iconlist-timeline" />
                </li>
                <li className="i-list-item">
                  <div className="icon">
                    {" "}
                    <i className="fa fa-diamond" />{" "}
                  </div>
                  <div className="icon-content">
                    <h3 className="title">BUSINESS PREMISES :</h3>
                  </div>

                  <div className="icon-content">
                    <h4 className="title">FACTORIESAND INDUSTRIAL PLOTS :</h4>
                    <p>
                      <ul className="bold">
                        <li>Selection of Plot for factory</li>
                        <li>Roads alignments</li>
                        <li>Entrance Gate</li>
                        <li>Machineries placements</li>
                        <li>Place of raw Material</li>
                        <li>Packing areas</li>
                        <li>Finished Products Area</li>
                        <li>Finished products loading bay</li>
                        <li>Place of Septik Tanks/Effluents Plants</li>
                        <li>Generators and power Plants</li>
                        <li>Cashier Place and cash box</li>
                        <li>Position of Staircases</li>
                        <li>Director's Room</li>
                        <li>Meeting Room/Conference Rooms</li>
                        <li>Training Halls</li>
                        <li>Employees seating areas</li>
                        <li>Government/Tax Departments Room</li>
                        <li>Quality Control Dept</li>
                        <li>Office Kitchen/Pantry</li>
                        <li>Pooja Area</li>
                        <li>Store</li>
                        <li>Quarters Area for employees</li>
                        <li>Gym and Recreation area</li>
                        <li>Washrooms/Toilets/Bathrooms areas</li>
                        <li>Water Tanks (Under Ground and Overhead Tanks)</li>
                        <li>Doors/Windows</li>
                        <li>Placements of Plants/Mirrors/Color of curtains</li>
                      </ul>
                    </p>
                  </div>
                  <div className="iconlist-timeline" />
                </li>
              </ul>
            </div>

            <div className="col-md-4">
              <ul className="i-list medium">
                <li className="i-list-item">
                  <div className="icon">
                    <i className="fa fa-codepen" />
                  </div>
                  <div className="icon-content">
                    <h3 className="title">BUSINESS PREMISES :</h3>
                  </div>
                  <div className="icon-content">
                    <h4 className="title">SHOPS AND SHOWROOMS :</h4>
                    <p>
                      <ul className="bold">
                        <li>Selection of Shops and showrooms</li>
                        <li>Roads alignments</li>
                        <li>Entrance Gate</li>
                        <li>Generators and power Plants</li>
                        <li>Cash Box/Cashier Place</li>
                        <li>Staircase</li>
                        <li>Employees seating areas/directions</li>
                        <li>Shop/showroom Kitchen/Pantry</li>
                        <li>Pooja Area</li>
                        <li>Stores for keeping goods</li>
                        <li>Display areas</li>
                        <li>Washrooms/Toilets/Bathrooms</li>
                        <li>Water Tanks (Under Ground and Overhead Tanks)</li>
                        <li>Doors/Windows</li>
                        <li>
                          Placements of Plants/Mirrors/Color/decoration items/
                          curtains
                        </li>
                      </ul>
                    </p>
                  </div>
                  <div className="iconlist-timeline" />
                </li>
                <li className="i-list-item">
                  <div className="icon">
                    <i className="fa fa-codepen" />
                  </div>
                  <div className="icon-content">
                    <h3 className="title">BUSINESS PREMISES :</h3>
                  </div>
                  <div className="icon-content">
                    <h4 className="title">HOTELS AND RESTAURANTS :</h4>
                    <p>
                      <ul className="bold">
                        <li>Selection of Plot/Hotels/Restaurants</li>
                        <li>Roads alignments</li>
                        <li>Entrance Gate/Exit Gates</li>
                        <li>Seating arrangement for Customers</li>
                        <li>Place of keeping of raw Material</li>
                        <li>Generators and power Plants</li>
                        <li>Cash Box/Cashier Place</li>
                        <li>Staircase</li>
                        <li>Employees seating/working areas</li>
                        <li>Quality Control Dept</li>
                        <li>Office Kitchen/Pantry</li>
                        <li>Pooja Area</li>
                        <li>Stores for different items</li>
                        <li>Placements of Freezers/Fridges/Air Conditioners</li>
                        <li>Washrooms/Toilets/Bathrooms</li>
                        <li>Water Tanks (Under Ground and Overhead Tanks)</li>
                        <li>Doors/Windows</li>
                        <li>Placements of Plants/Mirrors/Color of curtains</li>
                      </ul>
                    </p>
                  </div>
                  <div className="iconlist-timeline" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;

