import React from "react";
import ClientsArea from "./home/ClientsArea";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>About Us</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>About Us</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="design-desc-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tab-manu">
                <ul className="tab-manu-items text-center" role="tablist">
                  <li className="single-item" data-tab-name="resid">
                    <a
                      href="#resid"
                      aria-controls="resid"
                      role="tab"
                      data-toggle="tab"
                      className="clearfix"
                    >
                      ABOUT
                    </a>
                  </li>
                  <li className="single-item active" data-tab-name="commer">
                    <a
                      href="#commer"
                      aria-controls="commer"
                      role="tab"
                      data-toggle="tab"
                      className="clearfix"
                    >
                      TEAM WORK
                    </a>
                  </li>
                  <li className="single-item" data-tab-name="corp">
                    <a
                      href="#corp"
                      aria-controls="corp"
                      role="tab"
                      data-toggle="tab"
                      className="clearfix"
                    >
                      OUR CLIENTS
                    </a>
                  </li>
                  {/* <li className="single-item" data-tab-name="hospi">
                    <a
                      href="#hospi"
                      aria-controls="hospi"
                      role="tab"
                      data-toggle="tab"
                      className="clearfix"
                    >
                      Hospitality
                    </a>
                  </li>
                  <li className="single-item" data-tab-name="resta">
                    <a
                      href="#resta"
                      aria-controls="resta"
                      role="tab"
                      data-toggle="tab"
                      className="clearfix"
                    >
                      Restaurant
                    </a>
                  </li>
                  <li className="single-item" data-tab-name="indus">
                    <a
                      href="#indus"
                      aria-controls="indus"
                      role="tab"
                      data-toggle="tab"
                      className="clearfix"
                    >
                      Industrial
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade in" id="resid">
                  <div className="inner-content">
                    <div className="row">
                      <div className="col-md-6">
                        {/*Start design single carousel*/}
                        <div
                          id="design-single-carousel"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#design-single-carousel"
                              data-slide-to={0}
                              className="active"
                            />
                            <li
                              data-target="#design-single-carousel"
                              data-slide-to={1}
                            />
                            <li
                              data-target="#design-single-carousel"
                              data-slide-to={2}
                            />
                          </ol>
                          <div className="carousel-inner" role="listbox">
                            <div className="item active">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-1.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-2.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-3.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*End design single carousel*/}
                      </div>
                      <div className="col-md-6">
                        {/*Start text-holder*/}
                        <div className="text-holder">
                          <div className="sec-title-two">
                            <h3>ABOUT SPACE DESIGN STUDIO</h3>
                          </div>
                          <div className="text bold">
                            <p>
                              Space Design Studio Interior is renowned in the
                              field of Best Interior Designer In Hyderabad and
                              house decoration in Hyderabad. Our interior
                              designers cater to the needs of all the
                              requirements & necessities of Interior designing
                              of your Dream Home and Commercial Space.
                            </p>
                            <p>
                              Space Design Studio Interior is giving the best
                              opportunity to express your desire and taste in
                              order to meet present lifestyle. Contemporary
                              Style, Fixed or Loose Furniture and Modular design
                              of kitchen add to the beauty and functionality of
                              your home. Space Design Studio Interior offers a
                              wide range of selection of interior design in
                              Hyderabad to suit everyone's needs which reflect
                              his taste, style, and dignity.
                            </p>
                          </div>
                          <div className="caption-box">
                            <h3>
                              Space Design Studio Interior work station promotes
                              comfort, efficiency &amp; employer interaction.
                            </h3>
                          </div>
                        </div>
                        {/*End text-holder*/}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade in active" id="commer">
                  <div className="inner-content">
                    <div className="row">
                      <div className="col-md-6">
                        {/*Start design single carousel*/}
                        <div
                          id="design-single-carousel-0"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#design-single-carousel-0"
                              data-slide-to={0}
                              className="active-1"
                            />
                            <li
                              data-target="#design-single-carousel-0"
                              data-slide-to={1}
                            />
                            <li
                              data-target="#design-single-carousel-0"
                              data-slide-to={2}
                            />
                          </ol>
                          <div className="carousel-inner" role="listbox">
                            <div className="item active">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-1.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-2.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-3.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*End design single carousel*/}
                      </div>
                      <div className="col-md-6">
                        {/*Start text-holder*/}
                        <div className="text-holder">
                          <div className="sec-title-two">
                            <h3>ABOUT OUR TEAM WORK</h3>
                          </div>
                          <div className="text bold">
                            <p>
                              The speciality of Space Design Studio Interior is
                              3D and Visual Effects. Provide Us 4 Walls and get
                              your effect with realistic view. Space Design
                              Studio Interior believe in quality of work. Space
                              Design Studio is an endeavor to create a landmark
                              in the world of stylish interiors.
                            </p>
                            <p>
                              Our creative and experienced interior designers
                              provide exceptional 3D contemporary concept
                              designs which are offered to the client for a
                              better perspective of the potential design and a
                              clearer insight of the actual outcome after
                              project completion. Our in house team of creative
                              and experienced Best Interior Designer In
                              Hyderabad, decorators, and architects have the
                              relevant knowledge and flair to put together
                              original designs and concepts in each project.
                              Over the years, we have successfully completed
                              over countless number of interior turnkey
                              solutions projects in collaboration amongst
                              architects, designers and high skilled technicians
                              to ensure excellent finish and décor. We have the
                              full resources to manage the entire interior
                              project, from concept to execution, giving
                              customers total peace of mind and satisfaction.
                            </p>
                          </div>
                        </div>
                        {/*End text-holder*/}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade in" id="corp">
                  <div className="inner-content">
                    <div className="row">
                      <div className="col-md-6">
                        {/*Start design single carousel*/}
                        <div
                          id="design-single-carousel-1"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#design-single-carousel-1"
                              data-slide-to={0}
                              className="active"
                            />
                            <li
                              data-target="#design-single-carousel-1"
                              data-slide-to={1}
                            />
                            <li
                              data-target="#design-single-carousel-1"
                              data-slide-to={2}
                            />
                          </ol>
                          <div className="carousel-inner" role="listbox">
                            <div className="item active">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-1.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-2.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-3.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*End design single carousel*/}
                      </div>
                      <div className="col-md-6">
                        {/*Start text-holder*/}
                        <div className="text-holder">
                          <div className="sec-title-two">
                            <h3>WE AND OUR CLIENTS</h3>
                          </div>
                          <div className="text">
                            <p className="bold">
                              Schemes offer the most complete Best Interior
                              Designer In Hyderabad, furniture solutions, and
                              retail service available in the region. Our
                              service ranges from site feasibility studies,
                              conceptual design, production of technical
                              drawings and managing of tender process all the
                              way to execution of project on behalf of clients,
                              working alongside building owners, contractors and
                              relevant authorities.
                            </p>
                            <p className="bold">
                              We offer our clients an unique opportunity to
                              source their requirements through us. we are
                              experts in custom made furniture, curtains, Loose
                              Furniture and upholstery works thus greatly
                              facilitating new demands and requirements to fit
                              any space.
                            </p>
                          </div>
                          <div className="caption-box">
                            <h3>
                              Sometimes we work in partnership with the client
                              which is key to years of continuous success...
                            </h3>
                          </div>
                        </div>
                        {/*End text-holder*/}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="tab-pane fade in" id="hospi">
                  <div className="inner-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          id="design-single-carousel-2"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#design-single-carousel-2"
                              data-slide-to={0}
                              className="active"
                            />
                            <li
                              data-target="#design-single-carousel-2"
                              data-slide-to={1}
                            />
                            <li
                              data-target="#design-single-carousel-2"
                              data-slide-to={2}
                            />
                          </ol>
                          <div className="carousel-inner" role="listbox">
                            <div className="item active">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-1.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-2.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-3.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-holder">
                          <div className="sec-title-two">
                            <h3>Hospitality Design</h3>
                          </div>
                          <div className="text">
                            <p>
                              The extremely efficient and creative team at
                              Interior have worked hard to bring us in the list
                              of top commercal interior design companies in usa,
                              especially Newyork. We produce commercial interior
                              designs for all kinds of projects.{" "}
                            </p>
                            <p>
                              From the creation of concepts to space planning
                              leading to solutions, we take care of everything.
                              Generate the most creative ideas and brands hence
                              adding value to each interaction.
                            </p>
                          </div>
                          <div className="caption-box">
                            <h3>
                              Our experts let you choose best among all hence
                              making your space look the most stunning &amp;
                              desirable amoung all.
                            </h3>
                          </div>
                          <div className="botton-text-box">
                            <p>
                              We value our clients and are always available at
                              your service for any kind of commercial interior
                              design services.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="tab-pane fade in" id="resta">
                  <div className="inner-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          id="design-single-carousel-3"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#design-single-carousel-3"
                              data-slide-to={0}
                              className="active"
                            />
                            <li
                              data-target="#design-single-carousel-3"
                              data-slide-to={1}
                            />
                            <li
                              data-target="#design-single-carousel-3"
                              data-slide-to={2}
                            />
                          </ol>
                          <div className="carousel-inner" role="listbox">
                            <div className="item active">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-1.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-2.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-3.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-holder">
                          <div className="sec-title-two">
                            <h3>Restaurant Design</h3>
                          </div>
                          <div className="text">
                            <p>
                              The extremely efficient and creative team at
                              Interior have worked hard to bring us in the list
                              of top commercal interior design companies in usa,
                              especially Newyork. We produce commercial interior
                              designs for all kinds of projects.{" "}
                            </p>
                            <p>
                              From the creation of concepts to space planning
                              leading to solutions, we take care of everything.
                              Generate the most creative ideas and brands hence
                              adding value to each interaction.
                            </p>
                          </div>
                          <div className="caption-box">
                            <h3>
                              Our experts let you choose best among all hence
                              making your space look the most stunning &amp;
                              desirable amoung all.
                            </h3>
                          </div>
                          <div className="botton-text-box">
                            <p>
                              We value our clients and are always available at
                              your service for any kind of commercial interior
                              design services.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="tab-pane fade in" id="indus">
                  <div className="inner-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          id="design-single-carousel-4"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#design-single-carousel-4"
                              data-slide-to={0}
                              className="active"
                            />
                            <li
                              data-target="#design-single-carousel-4"
                              data-slide-to={1}
                            />
                            <li
                              data-target="#design-single-carousel-4"
                              data-slide-to={2}
                            />
                          </ol>
                          <div className="carousel-inner" role="listbox">
                            <div className="item active">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-1.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-2.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-3.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-holder">
                          <div className="sec-title-two">
                            <h3>Industrial Design</h3>
                          </div>
                          <div className="text">
                            <p>
                              The extremely efficient and creative team at
                              Interior have worked hard to bring us in the list
                              of top commercal interior design companies in usa,
                              especially Newyork. We produce commercial interior
                              designs for all kinds of projects.{" "}
                            </p>
                            <p>
                              From the creation of concepts to space planning
                              leading to solutions, we take care of everything.
                              Generate the most creative ideas and brands hence
                              adding value to each interaction.
                            </p>
                          </div>
                          <div className="caption-box">
                            <h3>
                              Our experts let you choose best among all hence
                              making your space look the most stunning &amp;
                              desirable amoung all.
                            </h3>
                          </div>
                          <div className="botton-text-box">
                            <p>
                              We value our clients and are always available at
                              your service for any kind of commercial interior
                              design services.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*Start choose area*/}
      <section
        className="choose-area"
        style={{ backgroundImage: "url(images/about/choose-bg.jpg)" }}
      >
        <div className="container">
          <div className="sec-title text-center">
            <h2>Why choose Interior</h2>
            <span className="border" />
          </div>
          <div className="row">
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-internet" />
                </div>
                <div className="text-holder bold1">
                  <h3>We have highly professional team</h3>
                  <p className="">
                    We have a team of creative and hard working professionals
                    with advance and innovative ideas.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-idea" />
                </div>
                <div className="text-holder bold1">
                  <h3>We are commited</h3>
                  <p>
                    We are commited to complete our work within the given time
                    period.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-construction" />
                </div>
                <div className="text-holder bold1">
                  <h3>We deliver best results</h3>
                  <p>
                    We always give our best work so that our clients can get as
                    what they want.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
          </div>
        </div>
        
      </section>
      {/*End choose area*/}

      {/*Start What we do*/}
      <section
        className="choose-area"
        style={{ backgroundImage: "url(images/about/choose-bg.jpg)" }}
      >
        <div className="container">
          <div className="sec-title text-center">
            <h2>OUR SERVICES</h2>
            <span className="border" />
          </div>
          <div className="row">
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-internet" />
                </div>
                <div className="text-holder">
                  <h3>Hotel & Resturant Interior Design</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-idea" />
                </div>
                <div className="text-holder">
                  <h3>Residential Interior Design</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-construction" />
                </div>
                <div className="text-holder">
                  <h3>Exterior design</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}

            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-internet" />
                </div>
                <div className="text-holder">
                  <h3>Flats</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-item">
                <div className="icon-holder">
                  <span className="flaticon-idea" />
                </div>
                <div className="text-holder">
                  <h3>Villas</h3>
                </div>
              </div>
            </div>
            {/*End single item*/}
          </div>
        </div>
      </section>
      {/*End What we do*/}

      <ClientsArea />
    </>
  );
};

export default AboutUs;
