import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper";

const ClientSlider = () => {
  return (
    <>
      <Swiper
        slidesPerView={5}
        spaceBetween={5}
        modules={[Autoplay]}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        className="ClientSwiper"
        breakpoints={{
          360: {
            slidesPreView: 2,
            spaceBetween: 5,
          },
          480: {
            slidesPreView: 3,
            spaceBetween: 5,
          },
          720: {
            slidesPreView: 4,
            spaceBetween: 5,
          },
          1080: {
            slidesPreView: 5,
            spaceBetween: 5,
          },
        }}
      >
        <SwiperSlide>
          <div className="single-item">
            <a to="#">
              <img src="images/clients/01.png" alt="Awesome Brand Image" />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="single-item">
            <a to="#">
              <img src="images/clients/02.png" alt="Awesome Brand Image" />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="single-item">
            <a to="#">
              <img src="images/clients/03.png" alt="Awesome Brand Image" />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="single-item">
            <a to="#">
              <img src="images/clients/04.png" alt="Awesome Brand Image" />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="single-item">
            <a to="#">
              <img src="images/clients/05.png" alt="Awesome Brand Image" />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="single-item">
            <a to="#">
              <img src="images/clients/06.png" alt="Awesome Brand Image" />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="single-item">
            <a to="#">
              <img src="images/clients/07.png" alt="Awesome Brand Image" />
            </a>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default ClientSlider;
