import React from "react";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>BLOG</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>BLOG</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="design-desc-area">
        <div className="container">
          <div className="tab-pane fade in active" id="commer">
            <div className="inner-content">
              <div className="row">
                <div className="col-md-4">
                  <div
                    id="design-single-carousel-0"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <ol className="carousel-indicators">
                      <li
                        data-target="#design-single-carousel-0"
                        data-slide-to={0}
                        className="active-1"
                      />
                      <li
                        data-target="#design-single-carousel-0"
                        data-slide-to={1}
                      />
                      <li
                        data-target="#design-single-carousel-0"
                        data-slide-to={2}
                      />
                    </ol>
                    <div className="carousel-inner" role="listbox">
                      <div className="item active">
                        <div className="single-item">
                          <div className="img-holder">
                            <img
                              src="images/services/service-single/design-1.jpg"
                              alt="Awesome Image"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="single-item">
                          <div className="img-holder">
                            <img
                              src="images/services/service-single/design-2.jpg"
                              alt="Awesome Image"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="single-item">
                          <div className="img-holder">
                            <img
                              src="images/services/service-single/design-3.jpg"
                              alt="Awesome Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="text-holder">
                    <div className="sec-title-two">
                      <h3>LIFE OF AN ARCHITECT</h3>
                    </div>
                    <hr />

                    <div className="text bold">
                      <p>
                        WHETHER YOU’RE AN ASPIRING ARCHITECT LOOKING FOR SOUND
                        ADVICE, OR MERELY AN ENTHUSIAST SCOPING HOT DESIGNS FROM
                        AROUND THE WORLD, THERE’S NO WRONG REASON TO CHECK OUT
                        THE BEST ARCHITECTURE BLOGS. INDEED, WHILE ARCHITECTURE
                        REMAINS ONE OF MANKIND’S OLDEST ART FORMS, THAT DOESN’T
                        STOP ORIGINAL IDEAS FROM EMERGING ON A NEAR DAILY BASIS.
                        WITH THE BEST ARCHITECTURE BLOGS AT YOUR DISPOSAL, YOU
                        CAN KEEP PACE WITH THOSE IDEAS AS SOON AS THEY SURFACE,
                        AND MAYBE ONE DAY CONTRIBUTE AN IDEA OF YOUR OWN. HERE
                        ARE THE 14 BEST ARCHITECTURE BLOGS. USE THEM AS YOUR
                        CAREER GUIDE OR AS YOUR DAILY DOSE OF BUILDING PORN.
                        AGAIN, THERE’S NO WRONG WAY TO DO IT.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />

      <section className="design-desc-area blogsection">
        <div className="container">
          <div className="tab-content">
            <div className="tab-pane fade in active" id="indus">
              <div className="inner-content">
                <div className="row" >
                  <div className="col-md-8">
                    {/*Start text-holder*/}
                    <div className="text-holder">
                      <div className="sec-title-two">
                        <h3>VISUALIZING ARCHITECTURE</h3>
                      </div>
                      <hr />
                      <div className="text bold">
                        <p>
                          TO CALL ARCHITECTURE A VISUAL MEDIUM WOULD BE AN
                          UNDERSTATEMENT TO SAY THE LEAST, HOWEVER VISUALIZING
                          ARCHITECTURE TRULY RUNS WITH THE CONCEPT. FOUNDED BY
                          ALEX HOGREFE, THE SITE CONCEIVES VARIOUS BUILDINGS,
                          CITIES, BRIDGES AND OTHER STRUCTURES FROM THE GROUND
                          UP, RENDERING SKETCHES AND 3D ILLUSTRATIONS ALIKE. AS
                          SUCH, THE BLOG OFFERS A POIGNANT WINDOW INTO BOTH THE
                          PROCESS AND CREATIVITY BEHIND ARCHITECTURAL PROJECTS,
                          BIG AND SMALL. THIS IS ONE OF THE BEST ARCHITECTURE
                          BLOGS FOR THOSE WHO TRULY WANT TO UNDERSTAND DESIGN
                          FROM A 21ST CENTURY PERSPECTIVE.
                        </p>
                      </div>
                    </div>
                    {/*End text-holder*/}
                  </div>
                  <div className="col-md-4">
                    {/*Start design single carousel*/}
                    <div
                      id="design-single-carousel-4"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <ol className="carousel-indicators">
                        <li
                          data-target="#design-single-carousel-4"
                          data-slide-to={0}
                          className="active"
                        />
                        <li
                          data-target="#design-single-carousel-4"
                          data-slide-to={1}
                        />
                        <li
                          data-target="#design-single-carousel-4"
                          data-slide-to={2}
                        />
                      </ol>
                      <div className="carousel-inner" role="listbox">
                        <div className="item active">
                          <div className="single-item">
                            <div className="img-holder">
                              <img
                                src="images/services/service-single/design-1.jpg"
                                alt="Awesome Image"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="single-item">
                            <div className="img-holder">
                              <img
                                src="images/services/service-single/design-2.jpg"
                                alt="Awesome Image"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="single-item">
                            <div className="img-holder">
                              <img
                                src="images/services/service-single/design-3.jpg"
                                alt="Awesome Image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*End design single carousel*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
