import React from "react";
import ClientsArea from "./home/ClientsArea";
import { Link } from "react-router-dom";

const OurApproach = () => {
  return (
    <>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>OUR APPROACH</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>OUR APPROACH</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="design-desc-area">
        <div className="container">
         
                <div className="tab-pane fade in active" id="commer">
                  <div className="inner-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sec-title-two">
                            <h3>THE DESIGN APPROACH</h3>
                          </div>
                        <div
                          id="design-single-carousel-0"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#design-single-carousel-0"
                              data-slide-to={0}
                              className="active-1"
                            />
                            <li
                              data-target="#design-single-carousel-0"
                              data-slide-to={1}
                            />
                            <li
                              data-target="#design-single-carousel-0"
                              data-slide-to={2}
                            />
                          </ol>
                          <div className="carousel-inner" role="listbox">
                            <div className="item active">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-1.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-2.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="single-item">
                                <div className="img-holder">
                                  <img
                                    src="images/services/service-single/design-3.jpg"
                                    alt="Awesome Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*End design single carousel*/}
                      </div>
                      <div className="col-md-6">
                        {/*Start text-holder*/}
                        <div className="text-holder">
                          <div className="sec-title-two">
                            <h3>TYPICAL PROJECT FLOW</h3>
                          </div>
                          <div className="text">
                            <ol type="1">
                              <li ><b>1. Introduction</b> : Now that you have chosen your home,</li>
                              <li><b>2. Selection</b> : Determine your budget. We do the rest. A timeline is agreed.</li>
                              <li><b>3. Partnership</b> : See how your vision unfolds as our design team produces as range of solutions designed to encapsulate your lifestyle and personality.</li>
                              <li><b>4. Visuals</b> : Your ideas are brought to life in a selection of computer-aided, three-dimensional designs of each room.</li>
                              <li><b>5. Turnkey</b> : Relax as we turn your vision of a dream home into a reality by handling the entire process from start to finish.</li>
                              <li><b>6. Welcome Home</b> : Once all the amenities have been connected, it’s time for you to step Inside....</li>
                            </ol>
                            {/* <p>
                              The speciality of Space Design Studio Interior is
                              3D and Visual Effects. Provide Us 4 Walls and get
                              your effect with realistic view. Space Design
                              Studio Interior believe in quality of work. Space
                              Design Studio is an endeavor to create a landmark
                              in the world of stylish interiors.
                            </p>
                            <p>
                              Our creative and experienced interior designers
                              provide exceptional 3D contemporary concept
                            
                            </p> */}
                          </div>
                        </div>
                        {/*End text-holder*/}
                      </div>
                    </div>
                  </div>
                </div>
               
             
        </div>
      </section>

     
    </>
  );
};

export default OurApproach;
