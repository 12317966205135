import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ImageModelPriview from "./components/ImageModelPriview";
import { imageModelStatus } from "../../redux/projectSlice";

import ReactDOM from "react-dom";
import Modal from "react-modal";
import { ModalBody, ModalHeader } from "reactstrap";
import SliderImageModelPriview from "./components/SliderImageModelPriview";
import { useGlobalContext } from "react";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 99999,
    backgroundColor: "#fce5cd"
  },
};

const SingleProject = () => {
  const { projecttotal, isProjectLoading, imagemodel } = useSelector(
    (store) => store.productAll
  );

  const params = useParams();
  const dispatch = useDispatch();

  const [project, setProject] = useState("");
  const [projectimage, setProjectimage] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showImage, setShowImage] = useState("");



  const [zIndex, setZindex] = useState(1);

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = (e, image, index) => {
    setIsOpen(true);
    setShowImage(image);
    setCurrentIndex(index);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const singleProjectUrl = params.single;
    const singleProject = projecttotal.find(
      (project) => project.slugUrl === singleProjectUrl
    );
    setProject(singleProject);
    setProjectimage(singleProject.projetImages);
    console.log(singleProject);
  }, [params.single]);

  const [modal, setModal] = useState(false);

  const openModel = (e, image, index) => {
    setModal(true);
    setCurrentIndex(index);
  };

  const next = (index) => {
    setCurrentIndex(index + 1);
  };

  // const { getPrevPage, getNextPage } = useGlobalContext();

  const getNextPage = () => {
    dispatch({
      type: "NEXT_PAGE",
    });
  };
  const getPrevPage = () => {
    dispatch({
      type: "Prev_PAGE",
    });
  };


  return (
    <>
      <section
        className="breadcrumb-area popapcontainer"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>{project.name}</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>{project.name}</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="project-single-area" className="project-single-v1-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div
                id="project-single-carousel"
                className="carousel slide"
                data-ride="carousel"
              >
                <div className="carousel-inner" role="listbox">
                  <div className="item active">
                    <div className="single-item">
                      <div className="img-holder">
                        <img
                          src={project.homeImage}
                          alt="Awesome Image"
                          style={{ width: 525, height: 400 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="project-info">
                <h3>{project.title}</h3>
                <p>{project.shortDescription}</p>
                <ul className="project-info-list">
                </ul>
              </div>
            </div>
          </div>

          <section id="project-area" className="pro-classic-v3-area">
            <div>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div>
                  <div className="carouselpopuphide" onClick={closeModal} style={{ color: "black" }}>
                    X
                  </div>
                  <div id="carouselExample" className="carousel slide">
                    <div className="carousel-inner">
                      <div className="carousel-item active">

                        <img
                        style={{width:"800px", height:"500px"}}
                          src={showImage.large}
                          className="d-block w-100"
                          alt="..."
                        />
                        <div style={{ textAlign: "center", marginTop: "19px" }}>
                          <a href="#" onClick={() => getPrevPage()} className="previous">« Previous</a>
                          <a href="#" onClick={() => getNextPage()} className="next">Next »</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>

            <div className="container ">
              <div className="row project-content masonary-layout filter-layout">
                {projectimage.map((image, index) => (
                  <div
                    className="single-project-item col-md-3 col-sm-4 col-xs-12 filter-item commer indus corp"
                    key={index}
                  >
                    <div className="img-holder">
                      <img src={image.large} alt="Awesome Image" />
                      <div className="overlay-style-2">
                        <div className="box">
                          <div className="content">
                            <div className="text-holder">
                              <Link>
                                <h3>{project.name}</h3>
                              </Link>
                              <p>{project.category}</p>
                            </div>
                            <div className="icon-holder">
                              <a
                                data-rel="prettyPhoto"
                                title="Interrio Project"
                              >
                                <i
                                  className="fa fa-camera"
                                  onClick={(e) => openModal(e, image, index)}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default SingleProject;
