import React from "react";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>CONTACT US</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>CONTACT US</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-v1-area">
        <div className="container">
          <div className="sec-title text-center bold">
            <h2>Get Touch with Us</h2>
            <p>
              For more info or inquiry about our project, and pricing please
              feel free to get in touch with us.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img-holder">
                <img src="images/resources/contact.jpg" alt="Awesome Image" />
              </div>
              <div className="contact-info">
                <div className="row">
                  {/*Start single item*/}
                  <div className="col-md-6">
                    <div className="single-item">
                      <div className="icon-holder">
                        <span className="flaticon-building" />
                      </div>
                      <div className="text-holder">
                        <h5>Address :</h5>
                        <p>
                    Pillar Number 143, # 49 & 50,<br /> 4th Floor, United Arcade,<br />  Attapur, Hyderabad,<br /> Telangana-500048
                    </p>
                      </div>
                    </div>
                  </div>
                  {/*End single item*/}
                  {/*Start single item*/}
                  <div className="col-md-6">
                    <div className="single-item">
                      <div className="icon-holder">
                        <span className="flaticon-technology" />
                      </div>
                      <div className="text-holder">
                        <h5>Contact :</h5>
                        <p>(+91) 800 800 9832</p>
                      </div>
                    </div>
                  </div>
                  {/*End single item*/}
                  {/*Start single item*/}
                  <div className="col-md-6">
                    <div className="single-item">
                      <div className="icon-holder">
                        <span className="flaticon-new-email-outline" />
                      </div>
                      <div className="text-holder">
                        <h5>Email :</h5>
                        <p>javidarchitects@gmail.com</p>
                      </div>
                    </div>
                  </div>
                  {/*End single item*/}
                 
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-form-v1">
                <form
                  id="contact-form"
                  name="contact_form"
                  action="inc/sendmail.php"
                  method="post"
                >
                  <input
                    type="text"
                    name="form_name"
                    // defaultValue
                    placeholder="Your Name*"
                    required
                  />
                  <input
                    type="email"
                    name="form_email"
                    // defaultValue
                    placeholder="Enter Email*"
                    required
                  />
                  <input
                    type="text"
                    name="form_phone"
                    // defaultValue
                    placeholder="Your Phone"
                  />
                  <textarea
                    name="form_message"
                    placeholder="Your Message.."
                    // required
                    defaultValue={""}
                  />
                  <input
                    id="form_botcheck"
                    name="form_botcheck"
                    type="hidden"
                    // defaultValue
                  />
                  <button
                    className="thm-btn bg-cl-1"
                    type="submit"
                    data-loading-text="Please wait..."
                  >
                    send message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
