import React from 'react'
import HomeBanner from './home/HomeBanner'
import WhyChoose from './home/WhyChoose'
import LastestWork from './home/LastestWork'
import LastestProject from './home/LastestProject'
import Decoration from './home/Decoration'
import WorkingArea from './home/WorkingArea'
import CustomerFeedback from './home/CustomerFeedback'
import BlogsArea from './home/BlogsArea'
import ClientsArea from './home/ClientsArea'

const Home = () => {
  return (
    <>
    <HomeBanner />
    <WhyChoose />
    <LastestWork />
    {/* <LastestProject /> */}
    {/* <Decoration /> */}
    {/* <WorkingArea /> */}
    {/* <CustomerFeedback /> */}
    {/* <BlogsArea /> */}
    <ClientsArea />
    
    </>
  )
}

export default Home