import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../config/BaseUrl";

const initialState = {
    categorytotal: localStorage.getItem("categorytotal")
      ? JSON.parse(localStorage.getItem("categorytotal"))
      : [],
    homeImage: "",
    categoryImage: "",
    isHomeImageLoading: true,
    isCatImageLoading: true,
    isCategoryLoading: true,
  };

  export const getCategory = createAsyncThunk(
    "Category/getCategory",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/category/all`;
        const resp = await axios(url);
        return resp.data.categories;
      } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
      }
    }
  );




  
  const CategorySlice = createSlice({
    name: "categoryAll",
    initialState,
    extraReducers: {

        [getCategory.pending]: (state) => {
            state.isCategoryLoading = true;
          },
          [getCategory.fulfilled]: (state, action) => {
            state.categorytotal = action.payload;
            localStorage.setItem("categorytotal", JSON.stringify(action.payload));
            state.isCategoryLoading = false;
          },
          [getCategory.rejected]: (state, action) => {
            state.isCategoryLoading = true;
          },


  
       

    },
  });

  export default CategorySlice.reducer;
  
