import React from "react";
import { Link } from "react-router-dom";

const WhyChoose = () => {
  return (
    <>
      <section className="welcome-area">
        <div className="container clearfix">
          <div className="sec-title">
            <h2>
              Welcome to <span>Space Design Studio</span>
            </h2>
            <span className="decor" />
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="text-holder ">
                <h3>
                  About Us <br /> <small>Who are we</small>
                </h3>

                <p>
                  Space Design Studio Interiors is a one stop destination in the
                  field of Best Interior Designer In Hyderabad and office
                  interior designers in Hyderabad. We cater to the needs of all
                  the requirements &amp; necessities of Interior designing of
                  your Dream Home and Commercial Space.
                </p>
                <p>
                  We provide the best chance to express your carving and taste
                  so as to meet present way of life. Contemporary Style Fixed or
                  Loose Furniture and Modular plan of kitchen add to the
                  magnificence and usefulness of your home. We offer a wide
                  range of choice of interior decorators to suit everybody's
                  needs which mirror his taste, style, and poise.
                </p>
                <div className="bottom">
                  <div className="button">
                    <Link className="thm-btn bg-cl-1" to="/about">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12 col-xs-12"
              style={{ marginTop: 40 }}
            >
              <div className="text-holder bold">
                <h3>
                  Space Design Studio <br />
                  <small style={{ fontWeight: "700", color: "#000" }}>
                    Why choose us
                  </small>
                </h3>
                <br />
                <h4>
                  <b>We have highly professional team</b>
                </h4>
                <p>
                  We have a team of creative and hard working professionals with
                  advance and innovative ideas.
                </p>
                <h4>
                  <b>We are commited</b>
                </h4>
                <p>
                  We are commited to complete our work within the given time
                  period.
                </p>
                <h4>
                  <b>We deliver best results</b>
                </h4>
                <p>
                  We always give our best work so that our clients can get as
                  what they want.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChoose;
