import React from "react";
import { Link } from "react-router-dom";
import ClientsArea from "../home/ClientsArea";

const Concept = () => {
  return (
    <>
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-bg.jpg)" }}
      >
        <div className="container-fluid text-center">
          <h1>3D DESIGN CONCEPT</h1>
          <div className="breadcrumb-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left pull-left">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </li>
                      <li>3D DESIGN CONCEPT</li>
                    </ul>
                  </div>
                  <div className="right pull-right">
                    <Link>
                      <i
                        className="fa fa-arrow-circle-o-right"
                        aria-hidden="true"
                      />
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="design-desc-area">
        <div className="container">
          <div className="tab-pane fade in active" id="commer">
            <div className="inner-content">
              <div className="row">
                <div className="col-md-6">
                  {/*Start design single carousel*/}
                  <div className="sec-title-two">
                    <h3>3D DESIGN APPROACH</h3>
                  </div>
                  <div
                    id="design-single-carousel-0"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <ol className="carousel-indicators">
                      <li
                        data-target="#design-single-carousel-0"
                        data-slide-to={0}
                        className="active-1"
                      />
                      <li
                        data-target="#design-single-carousel-0"
                        data-slide-to={1}
                      />
                      <li
                        data-target="#design-single-carousel-0"
                        data-slide-to={2}
                      />
                    </ol>
                    <div className="carousel-inner" role="listbox">
                      <div className="item active">
                        <div className="single-item">
                          <div className="img-holder">
                            <img
                              src="images/services/service-single/design-1.jpg"
                              alt="Awesome Image"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="single-item">
                          <div className="img-holder">
                            <img
                              src="images/services/service-single/design-2.jpg"
                              alt="Awesome Image"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="single-item">
                          <div className="img-holder">
                            <img
                              src="images/services/service-single/design-3.jpg"
                              alt="Awesome Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*End design single carousel*/}
                </div>
                <div className="col-md-6">
                  {/*Start text-holder*/}
                  <div className="text-holder">
                    <div className="sec-title-two">
                      <h3>SPACE DESIGN STUDIO</h3>
                    </div>
                    <div className="text bold">
                      <p>
                        Space Design Studio Interior Specialized with 3D and
                        Visual Effects. Give Us only 4 Walls and Get your effect
                        with realistic view. Space Design Studio Interior
                        believes in quality of Work. Space Design Studio is an
                        endeavor to create a landmark in the world of stylish
                        interiors.
                      </p>
                    </div>
                  </div>
                  {/*End text-holder*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Concept;
