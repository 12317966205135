import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./redux/categorySlice";
import productReducer  from "./redux/projectSlice";



export const store = configureStore({
    reducer: {
        categoryAll: categoryReducer,
        productAll: productReducer,
    }
})