import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper";

export default function HomeBanner() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[Autoplay]}
        className="mainSlider"
      >
        <SwiperSlide>
          <div className="swiperContainer">
            <div className="swipermain">
              <div className="textDesign">
                <h3>INTERIOR DECORATORS IN HYDERABAD</h3>
                <p> With Innovative Ideas</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiperContainer2">
            <div className="swipermain2">
              <div className="textDesign2">
                <h3>SPACE DESIGN STUDIO</h3>
                <p> Best Interior Designer In Hyderabad</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
