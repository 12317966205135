import { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Router,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./pages/components/Header";
import Footer from "./pages/components/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import MissionVision from "./pages/about/MissionVision";
import WhyUs from "./pages/about/WhyUs";
import OurApproach from "./pages/OurApproach";
import Concept from "./pages/approach/Concept";
import ProjectExect from "./pages/approach/ProjectExect";
import Gallery from "./pages/Gallery";
import Blog from "./pages/Blog";
import ContactUs from "./pages/ContactUs";
import { useDispatch } from "react-redux";
import { getCategory } from "./redux/categorySlice";
import { getProject } from "./redux/projectSlice";
import Service from "./pages/Service";
import SingleProject from "./pages/home/SingleProject";
import Awards from "./pages/Awards";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const dispatch = useDispatch();

  //getCategory
  dispatch(getCategory());

  //getProject
  dispatch(getProject());

  return (
    <>
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" index element={<AboutUs />} />
          <Route path="/mission" element={<MissionVision />} />
          <Route path="/whyus" element={<WhyUs />} />
          <Route path="/service" element={<Service />} />
          <Route path="/awards" element={<Awards />} />
          <Route path="/approach" element={<OurApproach />} />
          <Route path="/concept" element={<Concept />} />
          <Route path="/projectexect" element={<ProjectExect />} />
          <Route path="/gallery/:caturl" element={<Gallery />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/gallery/project/:single" element={<SingleProject />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
